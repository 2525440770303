import React, { useState } from "react"
import Seo from "../components/seo"
import styled from "styled-components"
import Layout from "../components/layout"
import useForm from "../utils/useForm"
import { LanguageContext } from "../context/languageContextProvider"
import Dong from "../images/qr/dong-qr-min.jpg"
import Mike from "../images/qr/mike-qr-min.jpg"

function Contact() {
  const { values, updateValue } = useForm({
    name: "",
    email: "",
    phone: "",
    message: "",
  })

  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState()
  const [message, setMessage] = useState("")
  const [language, setLanguage] = React.useContext(LanguageContext)

  async function submitForm(e) {
    e.preventDefault()
    setLoading(true)
    setError(null)
    // setMessage('Go eat!');

    // gather all the data
    const body = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message,
      mapleSyrup: values.mapleSyrup,
    }

    try {
      // 4. Send this data the a serverless function when they check out
      const res = await fetch(`/.netlify/functions/contactForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
      const text = JSON.parse(await res.text())
      setMessage(text.message)
      setLoading(false)
      setEmailSent(true)
    } catch (e) {
      setLoading(false) // turn off loading
      setError(e)
    }
  }

  return (
    <Layout>
      <Seo title="Contact" />
      <ContactHeader />
      <ContactMain>
        <h1>{language === "EN" ? "Contact Us" : "联系我们"}</h1>
        <QRBox>
          <img src={Mike} alt="Mike QR Code"></img>
          <img src={Dong} alt="Dong QR Code"></img>
        </QRBox>
        <ContactInfo>
          <h3>
            {language === "EN"
              ? "Call Us or Message Us"
              : "给我们打电话或给我们留言"}
          </h3>
          <div>
            <p>Michael Lin (Co-Founder) : (412)-403-3316</p>
            <p>WeChat ID : Ji_Lin_Mike</p>
          </div>
          <div>
            <p>Chengdong Yao (Manager) : (917)-803-3677</p>
            <p>WeChat ID: yaochengdong-</p>
          </div>
          <h3>{language === "EN" ? "Email Us" : "给我们发电子邮件"}</h3>
          <div>
            <p>the1weddingplaza@gmail.com</p>
          </div>
          <h3>{language === "EN" ? "Follow Us" : "跟着我们"}</h3>
          <div>
            <a
              href="https://www.facebook.com/profile.php?id=100090315949753&mibextid=LQQJ4d"
              target="_blank"
            >
              Facebook
            </a>
          </div>
          <h3>{language === "EN" ? "Visit Us" : "探望我们"}</h3>
          <div>
            <p>The ONE Wedding Plaza</p>
            <p>Second Floor</p>
            <p>59 E Broadway</p>
            <p>New York, NY 10002</p>
          </div>
        </ContactInfo>
        <ContactForm onSubmit={submitForm}>
          {!emailSent && (
            <fieldset disabled={loading}>
              <h2>
                {language === "EN" ? "Ask Us Your Questions" : "向我们提问"}
              </h2>
              <label htmlFor="name">
                {language === "EN" ? "Name" : "名字"}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={updateValue}
                required
              />
              <label htmlFor="email">
                {language === "EN" ? "Email" : "电子邮件"}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={updateValue}
                required
              />
              <label htmlFor="email">
                {language === "EN" ? "Phone" : "电话号码"}
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                value={values.phone}
                onChange={updateValue}
                required
              />
              <textarea
                name="message"
                id="message"
                placeholder={
                  language === "EN" ? "Your message..." : "你的信息..."
                }
                onChange={updateValue}
                required
              ></textarea>
              <input
                type="mapleSyrup"
                name="mapleSyrup"
                id="mapleSyrup"
                value={values.mapleSyrup}
                onChange={updateValue}
                className="mapleSyrup"
              />
            </fieldset>
          )}
          {emailSent && (
            <successBox>
              <p>Email Sent!</p>
            </successBox>
          )}
          <div>{error ? <errorText>Error: {error}</errorText> : ""}</div>
          <div>
            {message.length > 0 ? <messageText>{message}</messageText> : ""}
          </div>
          <ContactButton type="submit">
            {language === "EN" ? "Submit" : "递交"}
          </ContactButton>
        </ContactForm>
        <section style={{ height: "30rem", width: "100vw" }}>
          <ContactMap
            className="map"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=59%20E%20Broadway%20%20New%20York%2C%20New%20York%2010002+(The%20ONE%20Wedding%20Studio)&amp;ie=UTF8&amp;t=&amp;z=19&amp;iwloc=B&amp;output=embed"
            frameborder="0"
            scrolling="no"
            width={"100%"}
            height={"100%"}
          ></ContactMap>
        </section>
      </ContactMain>
    </Layout>
  )
}

export default Contact

const errorText = styled.p`
  color: red;
`

const messageText = styled.p`
  color: green;
`

const successBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    padding: 20vh 0;
  }
`

const ContactHeader = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(147, 181, 179, 0.1)
    ),
    url("https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086874/couples/couple-11v_eraf8p.jpg");
  width: 100%;
  height: 35vh;
  background-size: cover;
  background-position: center;
  margin-bottom: 3rem;

  @media screen and (max-width: 768px) {
    height: 25vh;
  }
`

const ContactMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    text-decoration: underline;
    margin-bottom: 3rem;
    text-align: center;
    font-family: "Noto Serif Display", serif;
  }
`

const QRBox = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  img {
    width: 250px;
    height: 250px;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
      width: 200px;
      height: 200px;
      gap: 0.75rem;
    }
  }
`

const ContactInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: black;
    transition: all 0.2s ease;

    &:hover {
      color: #93b5b3;
    }
  }
`
const ContactMap = styled.iframe`
  text-align: center;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
`

const ContactButton = styled.button`
  text-decoration: none;
  outline: none;
  border: none;
  color: white;
  background: #3e454d;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    opacity: 0.8;
  }
`

const ContactForm = styled.form`
  width: 80vw;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: 3rem auto 6rem auto;

  fieldset {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 600px;
    overflow: auto;
    width: 100%;
    border: none;

    input,
    textarea,
    label {
      width: 50%;
      padding: 5px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    input,
    textarea {
      margin-bottom: 2rem;
    }

    textarea {
      resize: none;
      overflow: auto;
      height: 500px !important;
    }
  }

  .mapleSyrup {
    display: none;
  }
`
